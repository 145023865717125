import React from 'react';
import { RedocStandalone } from 'redoc';
import './App.css';

function App() {
  return (
    <div className="App">
      <RedocStandalone
        specUrl="openapi.yaml"
        options={{
          nativeScrollbars: true,
          theme: {
            colors: { primary: { main: '#27ae7a' } },
            logo: {
              gutter: '20px',
            },
          },
        }}
      />
    </div>
  );
}

export default App;
